import React from 'react';
import { useSelector } from 'react-redux';
import useScrollToHash from '../utilities/useScrollToHash';

function FirstNations() {
  const currentTheme = useSelector(state => state.ui.theme);
  useScrollToHash();
  return (
    <section className={`section min-h-screen pt-32
    ${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>
      <h2 className='text-3xl font-bold mb-8 text-center'>First Nations Partnerships</h2>

      {/* Testimonials */}
      <div className=' py-6'>
        <h3 className='text-xl font-semibold mb-4'>Testimonials from Our Partners:</h3>
        <ul className='list-disc pl-5'>
          <li className='mb-4'>
            "Centex is very upfront and professional. Their respect and honesty go a long way in our relationship. If anyone is looking for a partner, connect with Centex." -- Chief Greg Desjarlais, Frog Lake Cree Nation
          </li>
          <li>
            "Having worked with Metrican Builders and Centex Petroleum, I strongly advocate other First Nations to use them. I have nothing but the highest amount of praise for Nirmal Gill and Alnoor Bhura. They operate their businesses like they live their lives with integrity and honesty. I 100% support them." — Chief Stanley Grier, Piikani First Nation
          </li>
        </ul>
      </div>

      {/* Commitment to Community Ownership */}
      <div className=' py-6'>
        <h3 className='text-xl font-semibold mb-4'>Our Commitment to Community Ownership</h3>
        <p className='mb-4'>
          "We are not driven by the desire to have more gas stations on First Nation sites. Our goal is to facilitate community ownership to benefit them. We are there to help throughout the entire process, from building the site to its operation." — Alnoor Bhura, CEO Centex
        </p>
      </div>

      {/* Advantages of Partnering with Centex */}
      <div className=' mb-8  py-6'>
        <h3 className='text-xl font-semibold mb-4'>Advantages of Partnering with Centex</h3>
        {/* Extensive Network and Reliable Supply */}
        <div className='mb-8'>
          <h4 className='text-lg font-semibold mb-2'>Extensive Network and Reliable Supply</h4>
          <ul className='list-disc pl-5'>
            <li>Canadian-Based Alternative Fuel Supply: Over 100 locations in Canada.</li>
            <li>Broad Geographic Reach: Locations in British Columbia, Alberta, and Ontario.</li>
            <li>First Nations Partnerships: 15 partnerships with First Nations communities.</li>
            <li>Expansion Plans: 18 new locations in development across four provinces.</li>
            <li>Guaranteed Fuel Supply: Agreements with three major refiners ensure fuel supply reliability.</li>
          </ul>
        </div>

        {/* Construction and Project Management */}
        <div className='mb-8'>
          <h4 className='text-lg font-semibold mb-2'>Construction and Project Management</h4>
          <ul className='list-disc pl-5'>
            <li>Cost Efficiency: Low upfront capital investment and costs.</li>
            <li>Project Coordination: Close collaboration with general contractors to save time and money.</li>
            <li>Comprehensive Support: Full-time project manager, project consultancy, cost estimates, budget projections, site plan analysis, equipment procurement, store design, and construction tendering.</li>
          </ul>
        </div>

        {/* Retail Support */}
        <div className='mb-8'>
          <h4 className='text-lg font-semibold mb-2'>Retail Support</h4>
          <ul className='list-disc pl-5'>
            <li>Sales Enhancement: Store planograms, merchandising, and product placement.</li>
            <li>Supplier Contacts: Access to promotional pricing and supplier contacts.</li>
            <li>Staff Training: Ongoing training for operations and health and safety.</li>
            <li>Food Partnerships: Access to a variety of food partnerships to enhance store offerings.</li>
          </ul>
        </div>

        {/* IT Support */}
        <div className='mb-8'>
          <h4 className='text-lg font-semibold mb-2'>IT Support</h4>
          <ul className='list-disc pl-5'>
            <li>Direct Support: Hands-on support from Centex head office IT staff, bypassing 1-800 numbers.</li>
            <li>Experienced Team: Expertise in IT issues, pay-at-the-pump systems, and support.</li>
            <li>Commercial Fuel Card: The FuelPass program offers automated fleet accounts for convenient fuel management.</li>
          </ul>
        </div>

        {/* Commercial Fuel Card */}
        <div>
          <h4 className='text-lg font-semibold mb-2'>Commercial Fuel Card</h4>
          <p>Our in-house FuelPass commercial fuel card allows you to provide your clients with automated fleet accounts. If you have a fleet of vehicles and are looking for a convenient solution to your fuel requirements, why not check out our Fuel Pass program.</p>
        </div>
      </div>

      {/* Featured Locations */}
      <div className=' mb-8  py-6'>
        <h3 className='text-xl font-semibold mb-4'>Featured Locations</h3>
        <div className='mb-4'>
          <div className='mb-2'>
            <h4 className='text-lg font-semibold mb-2'>Red Pheasant Cree Nation</h4>
            <p>Centex is proud to present one of our newest First Nations dealers.</p>
          </div>
          <div>
            <h4 className='text-lg font-semibold mb-2'>Piikani Nation</h4>
            <p>The Piikani Nation hosts one of our largest stores to date.</p>
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className='py-6'>
        <p className='text-lg'>Explore the benefits of partnering with Centex and join a network that values community, integrity, and success. Contact us today to learn more.</p>
      </div>
    </section>
  );
}

export default FirstNations;

