import React from 'react';
import { useSelector } from 'react-redux';
import useScrollToHash from '../utilities/useScrollToHash';

function CashBack() {
  const currentTheme = useSelector(state => state.ui.theme);
  useScrollToHash();
  return (
    <section className={`section min-h-screen pt-32
    ${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>
      <div className=' mb-5'>
        <h2 className='text-2xl font-semibold mb-2'>Centex Cashback Card</h2>
        <p className=''>The Centex Cashback Card offers a convenient way to earn cashback on fuel and in-store purchases. Here’s a comprehensive guide on how to use and maximize the benefits of the Centex Cashback Card and App:</p>
      </div>
      <div className=' mb-5'>
        <h1 className='text-xl font-semibold mb-2'>Getting Started</h1>
        <ul className='mb-5'>
          <h3 className='text-lg font-semibold mb-2'>Obtain a Card</h3>
          <li>Pick up a physical Centex Cashback Card at participating stores.</li>
          <li>Alternatively, download the Centex Cashback app to get a virtual card.</li>
        </ul>
      </div>
      <div className=' mb-5'>
        <h1 className='text-xl font-semibold mb-2'>Registering Your Card</h1>
        <ul className='mb-5'>
          <h3 className='text-lg font-semibold mb-2'>Instant Cashback</h3>
          <li>Cashback is awarded instantly, but you need to register your card through the Centex Cashback app or online to redeem it.</li>
          <li>Click the “Register” button on the app or website to complete the registration.</li>
        </ul>
      </div>
      <div className=' mb-5'>
        <h1 className='text-xl font-semibold mb-2'>Using the Card</h1>
        <ul className='mb-5'>
          <li>
            <h3 className='text-lg font-semibold mb-2'>At the Pump</h3>
            <ul>
              <li>Swipe your physical Centex Cashback Card before using your payment card.</li>
            </ul>
          </li>
          <li>
            <h3 className='text-lg font-semibold mb-2'>In-Store Purchases</h3>
            <ul>
              <li>Present your Centex Cashback card to the cashier.</li>
              <li>If using the app, show the barcode from the Centex Cashback app.</li>
            </ul>
          </li>
          <li>
            <h3 className='text-lg font-semibold mb-2'>Paying via Mobile App</h3>
            <ul>
              <li>Pre-pay for fuel inside the store and show the barcode on the Centex Cashback App to the cashier.</li>
            </ul>
          </li>
        </ul>
      </div>
      <div className=' mb-5'>
        <h1 className='text-xl font-semibold mb-2'>Earning Cashback</h1>
        <ul className='mb-5'>
          <li>
            <h3 className='text-lg font-semibold mb-2'>Fuel Purchases</h3>
            <ul>
              <li>Earn 2¢ cashback per litre of fuel.</li>
            </ul>
          </li>
          <li>
            <h3 className='text-lg font-semibold mb-2'>In-Store Purchases</h3>
            <ul>
              <li>Earn 2% cashback on eligible in-store purchases.</li>
            </ul>
          </li>
          <li>
            <h3 className='text-lg font-semibold mb-2'>Exclusions</h3>
            <ul>
              <li>Cashback is not earned on tobacco, alcohol, lottery tickets, prepaid phone cards, gift cards, transit passes, automotive services, and other excluded items.</li>
            </ul>
          </li>
        </ul>
      </div>
      <div className=' mb-5'>
        <h1 className='text-xl font-semibold mb-2'>Managing and Redeeming Cashback</h1>
        <ul className='mb-5'>
          <li>
            <h3 className='text-lg font-semibold mb-2'>Balance Management</h3>
            <ul>
              <li>View your cashback balance anytime via the Mobile App or online by clicking the “Balances” button.</li>
              <li>Transfer balances between registered virtual and physical cards.</li>
            </ul>
          </li>
          <li>
            <h3 className='text-lg font-semibold mb-2'>Redemption</h3>
            <ul>
              <li>Redeem cashback once your card is registered.</li>
              <li>Present your Centex Cashback card before finalizing a transaction.</li>
              <li>Minimum redemption amount is $5. Redeem in increments of $5, $10, $15, $20, $25, $50, or $100.</li>
            </ul>
          </li>
        </ul>
      </div>
      <div className=''>
        <h1 className='text-xl font-semibold mb-2'>Gift Cards</h1>
        <ul className=''>
          <li>
            <h3 className='text-lg font-semibold mb-2'>Centex Gift Card</h3>
            <ul>
              <li>Reloadable for any amount.</li>
              <li>Redeemable for most purchases at participating Centex locations.</li>
              <li>No activation fee.</li>
              <li>If registered, check the remaining balance through the app.</li>
              <li>Not redeemable for cash and Centex is not responsible for lost, stolen, or damaged cards.</li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default CashBack;
