import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../Logo';
import HeaderDropdown from '../ui/HeaderDropdown';
import MobileHeader from './MobileHeader';
import LanguageSwitchButton from '../ui/LanguageSwitchButton';
import HeaderLink from '../ui/HeaderLink';
import SearchWebsite from '../ui/SearchWebsite';
import HeaderLinkButton from '../ui/HeaderLinkButton';
import ThemeButton from '../ui/ThemeButton';
import { useSelector } from 'react-redux';

function Header() {
    const [isScrolled, setIsScrolled] = useState(false);
    const currentTheme = useSelector(state => state.ui.theme);

    // scroll event listener to change header visibility
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            // Adjust the threshold value as needed
            const threshold = 100;

            if (scrollPosition > threshold) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const language = useSelector((state) => state.ui.language);
    useEffect(() => {
        const googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement({
                pageLanguage: 'en',
                includedLanguages: 'en,fr',
                autoDisplay: false
            }, 'google_translate_element');

            // Function to select the language from the dropdown
            const selectLanguage = () => {
                const select = document.querySelector('.goog-te-combo');
                if (select) {
                    select.value = language === "fr" ? "fr" : "en";
                    select.dispatchEvent(new Event('change'));
                } else {
                    // Retry if select element is not available
                    setTimeout(selectLanguage, 500); // Adjust the delay as needed
                }
            };

            selectLanguage();
        };

        const handleScriptLoad = () => {
            // Initialize Google Translate Element after script load
            googleTranslateElementInit();
        };

        // Check if the Google Translate script is already present
        if (!document.querySelector('script[src*="translate.google.com"]')) {
            const addScript = document.createElement('script');
            addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=handleScriptLoad');
            document.body.appendChild(addScript);
            window.handleScriptLoad = handleScriptLoad; // Ensure the function is accessible globally
        } else {
            handleScriptLoad();
        }

        // Clean up the script if the component unmounts
        return () => {
            const script = document.querySelector('script[src*="translate.google.com"]');
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, [language]);

    return (
        <header
            id='header'
            className={`section py-2 fixed w-full z-30 justify-center shadow transition-all
             ${currentTheme === "light" ? "bg-white text-gray-800" : "bg-[#072841] text-gray-300"}`}
        >
            {/* Conditional rendering for the sub-header */}
            {isScrolled ? null : (
                <div className='justify-between pt-2 hidden md:flex'>
                    <NavLink to={"/"} className="h-full ">
                        <Logo />
                    </NavLink>
                    <nav className='text-black flex gap-3 hover:text-blue-950 justify-end text-xl'>
                        <SearchWebsite />
                        <LanguageSwitchButton />
                        <ThemeButton />
                        <div id="google_translate_element" className='hidden'></div>
                    </nav>
                </div>
            )}
            {/* Main header stays on scroll */}
            <div className={`py-3 w-full flex justify-between transition-all duration-700 items-center text-xs xl:text-base`}>
                {/* Logo on the left of main header */}
                <NavLink to={"/"} className="h-full md:hidden">
                    <Logo />
                </NavLink>

                {/* Navigation */}
                <div className='gap-2 hidden md:flex'>
                    <HeaderLinkButton title={"Fuel Program"} link={"/fuelprogram/"} />
                    <HeaderLinkButton title={"Cashback Loyalty"} link={"/cashback/"} />
                </div>
                <nav className={`hidden md:flex gap-x-2 md:gap-x-6 items-baseline transition-all
                ${currentTheme === "light" ? "centexTextColour" : "text-white"}`}>
                    <HeaderLink title={"First Nations"} link={"/firstnations/"} />
                    <HeaderLink title={"Go Market"} link={"/gomarket/"} />
                    <HeaderDropdown dropdownTitle={"What We Do"} dropdownLink={"/about/"}
                        links={[
                            // { name: 'About Us', link: '/about/' },
                            { name: 'Our History', link: '/about/history' },
                            { name: ' Our Communities', link: '/About/Communities/' },
                        ]}
                    />
                    <HeaderDropdown dropdownTitle={"Our Locations"} dropdownLink={"/locations/"}
                        links={[
                            { name: 'Carwash', link: '/locations/#carwash' },
                            { name: 'Cashback', link: '/locations/#cashback' },
                            { name: 'Diesel Fule', link: '/locations/#dieselfuel' },
                        ]}
                    />
                    <HeaderDropdown dropdownTitle={"Opportunities"} dropdownLink={"/Opportunities/"}
                        links={[
                            { name: 'Franchise / Dealer', link: '/Opportunities/' },
                            { name: 'Store Operator', link: '/Opportunities/' },
                            { name: 'Career', link: '/Opportunities/' },
                        ]}
                    />
                </nav>
                <MobileHeader />
            </div>
        </header>
    );
}

export default Header;