import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getAllLocations, selectAllLocations } from '../../redux/locations/locationSlice';
import GoogleMaps from '../../Sections/GoogleMaps';
import BlueSpinner from '../../components/spinnders/Spinners';
import { useLocation } from 'react-router-dom';
import Search from '../../components/ui/SearchBar';
import { FILTER_BY_CARWASH, FILTER_BY_CASHBACK, FILTER_BY_FIRSTNATIONS, FILTER_BY_FUELTYPE, FILTER_BY_SEARCH, SORT_BY_DISTANCE, selectFilteredLocations, } from '../../redux/Filter/filterSlice';
import LocationInfoCard from '../../components/ui/LocationInfoCard';
import ToggleButton from '../../components/ui/ToggleButton';
import useScrollToHash from '../../utilities/useScrollToHash';

function Locations() {
    useScrollToHash();
    const location = useLocation();

    const dispatch = useDispatch(); // Retrieve the dispatch function
    const centexLocations = useSelector(selectAllLocations); // Retrieve all locations from the Redux store
    const isLoading = useSelector(state => state.locations.isLoading); // Retrieve the loading state from the Redux store
    const currentTheme = useSelector(state => state.ui.theme);

    const [search, setSearch] = useState("");
    const filteredLocations = useSelector(selectFilteredLocations);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [hoveredLocation, setHoveredLocation] = useState(null);

    //User location
    const [userLocation, setUserLocation] = useState(null);
    useEffect(() => {
        if (isLoading) return;

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    setUserLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                },
                error => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, [isLoading, navigator]);

    // read URL to filter locations
    useEffect(() => {
        console.log("use effect ran");
        
        const hash = location.hash;

        if (centexLocations.length === 0) {
            dispatch(getAllLocations());
        }

        if (hash === "#firstNations") {
            dispatch(FILTER_BY_FIRSTNATIONS({ locations: centexLocations }));
        } else if (hash === "#carwash") {
            dispatch(FILTER_BY_CARWASH({ locations: centexLocations }));
        } else if (hash === "#cashback") {
            dispatch(FILTER_BY_CASHBACK( {locations: centexLocations} ))
        } else if (hash === "#regularfuel") {
            const fuelType = "regular";
            dispatch(FILTER_BY_FUELTYPE({ locations: centexLocations, fuelType }));
        } else if (hash === "#midgradefuel") {
            const fuelType = "midgrade";
            dispatch(FILTER_BY_FUELTYPE({ locations: centexLocations, fuelType }));
        } else if (hash === "#premiumfuel") {
            const fuelType = "premium";
            dispatch(FILTER_BY_FUELTYPE({ locations: centexLocations, fuelType }));
        } else if (hash === "#dieselfuel") {
            const fuelType = "diesel";
            dispatch(FILTER_BY_FUELTYPE({ locations: centexLocations, fuelType }));
        } else {
            dispatch(FILTER_BY_SEARCH({ locations: centexLocations, search }));
        }
        
        // (userLocation != null && filteredLocations != null) && dispatch(SORT_BY_DISTANCE({ locations: filteredLocations, userLocation }))

    }, [centexLocations, location, dispatch, search]);

    // useEffect(()=>{
    //     if (centexLocations.length !== 0) {
    //         dispatch(CALCULATE_DISTANCE({ locations:centexLocations, userLocation }));
    //         setLocationsWithDistance(filteredLocations);
    //     }
    // }, [userLocation])

    // Filter by search
    useEffect(() => {
        dispatch(FILTER_BY_SEARCH({ locations: centexLocations, search }));
    }, [search])

    return (
        <div className={`pt-0 min-h-screen ${currentTheme === "light" ? "bg-gray-100 text-gray-800" : "bg-[#072841] text-gray-300"}`}>
            {isLoading ? (
                <BlueSpinner />
            ) : (
                <div className='lg:flex'>
                    <div className='w-full h-[55vh] lg:h-[100vh] pt-28'>
                        <GoogleMaps locations={filteredLocations} hoveredLocation={hoveredLocation} selectedLocation={selectedLocation} userLocation={userLocation} />
                    </div>

                    <div className={`p-2 left-3 top-32 lg:absolute lg:w-[450px] backdrop-blur-xl rounded-lg transition-all border
                    ${currentTheme === "light" ? "backdrop-brightness-90 border-gray-300" : "backdrop-brightness-75 border-gray-600 bg-[#0728414f]"}`}>
                        <Search value={search} placeholder="Search All Locations" onChange={(e) => { setSearch(e.target.value) }} onClear={() => setSearch("")} />
                        <div className='flex gap-1 justify-center mt-2 hover:opacity-100 lg:opacity-70 transition-all text-sm'>
                            <ToggleButton link={""} name={"All"} hash={location.hash} />
                            <ToggleButton link={"#carwash"} name={"Carwash"} hash={location.hash} />
                            <ToggleButton link={"#cashback"} name={"Cashback"} hash={location.hash} />
                            {/* <ToggleButton link={"#firstNations"} name={"First Nations"} hash={location.hash} /> */}
                            <ToggleButton link={"#dieselfuel"} name={"Diesel Fuel"} hash={location.hash} />
                        </div>
                        {userLocation !== null &&
                            <div className='flex flex-wrap gap-2 p-1 rounded mt-2 items-center'>
                                <h2 className=''>Sort by:</h2>

                                <button
                                    onClick={() => { dispatch(SORT_BY_DISTANCE({ locations: filteredLocations, userLocation })); }}
                                    className='hover:scale-105 font-thin transition-transform px-2 hover:opacity-100 lg:opacity-70 bg-[#0f4f80] text-white rounded'
                                >
                                    Distance
                                </button>
                            </div>
                        }
                        <ul className='overflow-auto h-[66vh] pr-1 mt-2'>
                            {filteredLocations?.map((location) => (
                                <li
                                    key={location.id}
                                    className={`mb-3  hover:opacity-100 opacity-70 shadow-lg transition-all cursor-pointer rounded
                                    ${currentTheme === "light" ? "bg-white text-gray-800" : "bg-[#184161] text-gray-300"}`}
                                    onMouseEnter={() => setHoveredLocation(location.id)}
                                    onMouseLeave={() => setHoveredLocation(null)}
                                    onClick={
                                        () => {
                                            selectedLocation === location.id ? setSelectedLocation(null) : setSelectedLocation(location.id)
                                        }}
                                >
                                    <LocationInfoCard location={location} selectedlocation={selectedLocation} onOpen={() => selectedLocation === location.id ? setSelectedLocation(null) : setSelectedLocation(location.id)} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Locations;