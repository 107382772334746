import React from 'react'
import { useSelector } from 'react-redux';
import { NavLink} from 'react-router-dom';

function ToggleButton({ name, link, hash }) {
    const currentTheme = useSelector(state => state.ui.theme);

    return (
        <NavLink to={`./${link}`}
            className={` w-[100%] text-center text-white py-1 rounded hover:scale-105 active:scale-100 shadow-inner hover:shadow-md
            ${hash === link ? "centexOranceBg shadow-xl" : "bg-[#0f4f80]"}
             ${currentTheme === "light" ? "" : " "}
            `}
        >
            {name}
        </NavLink>
    )
}

export default ToggleButton