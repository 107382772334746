import React from 'react'
import { useSelector } from 'react-redux';
import useScrollToHash from '../utilities/useScrollToHash';

function Communities() {
    const currentTheme = useSelector(state => state.ui.theme);
    useScrollToHash();
    return (
        <section className={`section min-h-screen pt-32
    ${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>
            <div className='mb-5' id='PovertyReliefAfrica'>
                <h2>Poverty Relief Africa</h2>
                <p>Poverty Relief Africa has the mission to relieve the physical, emotional and intellectual poverty facing children and women in war-torn northern Uganda through education, life skills/leadership training and support for income generation.</p>
                <p>Their goal is to develop the next generation of community leaders in Gulu, northern Uganda, by supporting quality primary and secondary education and post-secondary academic and vocational programs to 100 students victimized by a brutal 20 year insurgency.</p>
                <p>Second Hope- they are assisting women in extreme poverty to become self-sufficient through support for collaboration, training and income generation activities.</p>
                <h3>Centex is a proud supporter of Poverty Relief Africa.</h3>
                <p>For more information: <a href='www.canadahelps.org/en/charities/poverty-relief-africa'>Click Here</a></p>
            </div>
            <div className='mb-5' id='MalawiWaterProject'>
                <h2>Malawi Water Project</h2>
                <h3>Providing Clean Water, Saving Lives</h3>
                <p>Malawi is a small landlocked country in southeastern sub-Saharan Africa, and is among the <a href='https://en.wikipedia.org/wiki/List_of_countries_by_Human_Development_Index'>world's least-developed countries.</a></p>
                <p>The goal of the MALAWI WATER PROJECT is to improve health and alleviate suffering due to water borne illness by providing clean drinking water to vulnerable children and families in rural Malawi. The Project aims to improve health in rural communities by training the local villagers on proper sanitation and hygiene, and to assist local teams in the construction and installation of Biosand Water Filters, which provides safe water to households. The Biosand Filter (BSF) is a simple household water treatment device that is made of low cost local materials, and was originally designed at the University of Calgary, Canada.  It is proven to be very effective in removing disease causing pathogens and can last a lifetime!</p>
                <h3>Centex gives annually and is a proud supporter of the Malawi Water Project.</h3>
                <p>For more information: <a href='www.malawiwaterproject.com'>Click Here</a></p>
            </div>
            <div className=' mb-5' id='Mom2MomGlobal'>
                <h2>Mom2Mom Global</h2>
                <h3>Even better support than your nursing bra!</h3>
                <p>Mom2Mom Global is a worldwide network of breastfeeding support and advocacy for military families. Our purpose is to provide consistent, high-quality peer support through local chapters and liaisons at U.S. military installations, to increase access to accredited lactation professionals for all military families, and to provide a channel to raise awareness and solutions for the concerns of military breastfeeding families.</p>
                <h3>Centex is a proud supporter of Mom2Mom Global.</h3>
                <p>For more information: <a href='www.mom2momglobal.org'>Click Here</a></p>
            </div>
            <div className='mb-5' id='ChildHavenInternational'>
                <h2>Child Haven International</h2>
                <h3>We believe it's very important to give back.</h3>
                <p>If Centex could have one wish, we'd like to see kids in unfortunate circumstances get the help they need to not only survive but succeed.</p>
                <h3>We direct our focus towards development through a strong relationship with <a href='http://www.childhaven.ca/'>Child Haven International.</a></h3>
                <p>We give annually and we're working on putting together a 'Child Haven Week' at all of our locations. With every purchase for the week, customers will be able to donate a Loonie ($1.00 CAD) towards the orphanages run by Child Haven and Centex will match every donation. It's coming soon, so stay tuned for more updates.</p>
                <p>Inspired by the ideals of Mahatma Gandhi, Child Haven International assists children and women in developing countries, who are in need of food, education, health care, shelter, clothing, emotional & moral support.</p>
                <p>For more information: <a href='www.childhaven.ca'>Click Here</a></p>
            </div>
            <div className=' mb-5' id='UmojaCommunityMosaic'>
                <h2>Umoja Community Mosaic</h2>
                <p>Umoja Community Mosaic began in 2010 as a drop-in soccer program called Soccer Without Boundaries. Soccer Without Boundaries operated as a small grassroots organization with a big impact on social inclusion. Through soccer, we have built healthy communities - we have created an environment that allows young people to shine, with a motto, “Everyone is welcome”.</p>
                <p>In 2020, we widened the scope of our services in response to the Covid-19 pandemic, and increased our organization's mandate. We changed our name to Umoja Community Mosaic to reflect this expansion using the word “Umoja”, meaning “unity” and “belonging” in Swahili.</p>
                <p>In response to the voices coming from the communities we serve, we began a culturally appropriate food and nutrition program for low income and underserved Calgarians. We are also continuing to provide after-school programming and tutoring. In addition to our weekly soccer programming and summer soccer camps, we are planning a girls soccer program (for older youth) to begin hopefully in the new year. We have also formally incorporate anti-racism into all of our work, along with a focus on mental health into our regular soccer programming.</p>
                <p>For more information: <a href='https://umojamosaic.org/'>Click Here</a></p>
            </div>
            <div className='' id='ChildIsInnocent'>
                <h2>The Child is Innocent</h2>
                <p>Founded in Northern Uganda in 2004, The Child is Innocent believe that those best suited to rebuild and develop a post-conflict community in the face of poverty are members of that same community empowered with high-quality formal education, leadership skills and a network of peer support to improve the lives of their friends, families, and neighbors.</p>
                <p>The Child is Innocent provides comprehensive educational scholarships to children from Northern Uganda ages 7-22 years old to attend some of the best boarding schools in Uganda and couples this with an innovative leadership curriculum run by the graduates of our scholarship program to create both opportunity and a community of support emerging from Northern Uganda itself.</p>
                <p>For more information: <a href='https://www.thechildisinnocent.org/'>Click Here</a></p>
            </div>
        </section>
    )
}

export default Communities