import React from 'react';
import { FaMapMarker, FaPhone } from 'react-icons/fa';
import { IoIosArrowDown } from "react-icons/io";
import { FaDirections } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import HeaderLinkButton from './HeaderLinkButton';

const LocationInfoCard = ({ location, selectedlocation, onOpen, }) => {
    return (
        <div className={`rounded grid w-full p-2  ${selectedlocation === location.id && "border-l-8 border-[#F99D24]"}`} >
            <h2 className='text-xl font-semibold mb-2 col-span-4'>{location.title.rendered}</h2>
            <ul className='col-span-3'>
                {/* <li><strong>ID:</strong> {location.id}</li> */}
                {location.acf.phone_number && <li className='flex gap-2'><FaPhone />{location.acf.phone_number}</li>}
                <li className='flex gap-2'><FaMapMarker />{location.acf.address + ", " + location.acf.city + ", " + location.acf.provence}</li>
            </ul>
            <div className='col-span-1 row-span-2 gap-4 flex justify-end text-2xl'>
                {location.distance &&
                    <button
                        className='w-10 '
                        title='Your Distance from Location'
                    >
                        <FaDirections className='mx-auto text-base' />
                        <p className='text-xs'>{location.distance.toFixed(0)} km</p>
                    </button>
                }
                <button onClick={onOpen} title='More Info' className='hover:scale-125'>
                    <IoIosArrowDown className={`transition-all ${selectedlocation === location.id && "rotate-180"}`} />
                </button>
            </div>
            <div className={`transition-all overflow-hidden text-sm w-full col-span-4 h-0
                ${selectedlocation === location.id && "h-full pt-2 pb-2 mt-2"}`}>
                <div className='flex w-full justify-center pb-2 gap-2'>
                    {/* Services */}
                    <div className='w-full p-1 rounded'>
                        <h2 className='font-light text-lg border-b-2'>Amenities</h2>
                        <div className="flex flex-col gap-2">
                            <ul>
                                {Object.entries(location.acf).map(([service, value]) => {
                                    // Check if the service is available and not null
                                    if (value && (service === "car_wash" || service === "cstore_brand_name" || service === "amenity_atm" || service === "lotto" || service === "coffee" || service === "ice_cream" || service === "propane_cylinders")) {
                                        // console.log(value + " and " + service);
                                        return (
                                            <li key={service} className='items-center font-normal text-sm'>
                                                {/* {service.replace(/_/g, ' ')}:  */}
                                                {(service === "amenity_atm" || service === "lotto" || service === "car_wash") ? service.replace(/_/g, ' ') : value}
                                            </li>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                    {/* Fule */}
                    <div className='w-full p-1 rounded'>
                        <h2 className='font-light text-lg border-b-2'>Fule</h2>
                        <div className="flex flex-col gap-2">
                            <ul>
                                {Object.entries(location.acf).map(([service, value]) => {
                                    // Check if the service is available and not null
                                    if (value && (service === "regular" || service === "midgrade" || service === "premium" || service === "diesel")) {
                                        // console.log(value + " and " + service);
                                        return (
                                            <li key={service} className='items-center font-normal text-sm'>
                                                {/* {service.replace(/_/g, ' ')}:  */}
                                                {service}
                                            </li>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Hours */}
                <div className="font-thin w-fit p-1 rounded">
                    <h2 className='font-light text-lg border-b-2'>Hours</h2>
                    <ul className='w-fit h-fit flex flex-wrap gap-2 gap-x-10'>
                        {Object.entries(location.acf).map(([service, value]) => {
                            // Check if the service is available and not null
                            if (value && service.includes("hours")) {
                                // Determine the day of the week based on the service key
                                let day;
                                switch (service) {
                                    case "hours_sunday1":
                                        day = "Sun";
                                        break;
                                    case "hours_monday1":
                                        day = "Mon";
                                        break;
                                    case "hours_tuesday1":
                                        day = "Tue";
                                        break;
                                    case "hours_wednesday1":
                                        day = "Wed";
                                        break;
                                    case "hours_thursday1":
                                        day = "Thu";
                                        break;
                                    case "hours_friday1":
                                        day = "Fri";
                                        break;
                                    case "hours_saturday1":
                                        day = "Sat";
                                        break;
                                    default:
                                        return null;
                                }
                                return (
                                    <li key={service} className="flex border-b w-fit">
                                        <p className='w-14'>{day}:</p>
                                        <p className='font-normal'>{value}</p>
                                    </li>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </ul>
                </div>
                {/* <NavLink
                    to={`/locations/${location.id}`}
                    className={""}
                >
                    View More Details
                </NavLink> */}
                <div className='pt-5 text-md'>
                    <HeaderLinkButton link={`/locations/${location.id}`} title={"Learn More"} />
                </div>
            </div>

        </div>
    );
};

export default LocationInfoCard;