import React from 'react'
import HeaderLinkButton from '../components/ui/HeaderLinkButton'
import { useSelector } from 'react-redux';

function ImageAndInfobox({ img, title, text, text2, link, linkName, id}) {
    const currentTheme = useSelector(state => state.ui.theme);
    return (
        <div id= {id} className="flex flex-col lg:flex-row items-center section py-10 mx-auto lg:px-32">
            <img src={img} className="w-full lg:w-1/2 lg:h-[500px] object-cover rounded-lg mb-6 lg:mb-0" />
            <div className={`w-full lg:w-1/2 lg:-ml-16 p-8 rounded-xl backdrop-blur-md shadow-lg flex flex-col justify-between gap-5
                ${currentTheme === "light" ? "backdrop-brightness-90 bg-[#ffffff8e]" : "backdrop-brightness-75 bg-[#09335394]"}`}
            >
                <h2 className={`text-3xl font-bold ${currentTheme === "light" ? "centexTextColour" : "centexOrangeText"}`}>
                    {title}
                </h2>
                <div>
                    <p className="text-xl pb-5">{text}</p>
                    {text2 && <p>{text2}</p>}
                </div>
                <div className="pt-5">
                    <HeaderLinkButton link={link} title={linkName} />
                </div>
            </div>
        </div>
    )
}

export default ImageAndInfobox