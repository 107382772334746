import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../redux/UI/uiSlice';

const LanguageSwitchButton = () => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(state => state.ui.language);
  const currentTheme = useSelector(state => state.ui.theme);

  const handleLanguageSwitch = () => {
    const newLanguage = currentLanguage === 'en' ? 'fr' : 'en'; // Toggle between 'en' and 'fr'
    dispatch(setLanguage(newLanguage));
  };

  return (
    <button
      className={`transition-all text-sm rounded-full shadow-md text-center w-9 active:shadow-inner h-9
      ${currentTheme === "light" ? "bg-gray-100 text-gray-800 hover:bg-gray-200" : "bg-[#0f4f80] text-white hover:bg-[#0A3351]"}`}
      onClick={handleLanguageSwitch}
      title='Change Language'
    >
      {currentLanguage === 'fr' ? 'Fr' : 'En'}
    </button>
  );
};

export default LanguageSwitchButton;
