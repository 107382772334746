import React, { useEffect, useState } from 'react';
import { Button, Carousel, IconButton } from "@material-tailwind/react";
import { useSelector } from 'react-redux';
import HeaderLinkButton from '../components/ui/HeaderLinkButton';

function HomeCarousel({ allPromos }) {

    const currentTheme = useSelector(state => state.ui.theme);

    return (
        <div className={`${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>
            <Carousel
                className="z-0"
                navigation={({ setActiveIndex, activeIndex, length }) => (
                    <div className="absolute bottom-5 left-2/4 z-20 flex -translate-x-2/4 gap-2">
                        {new Array(length).fill("").map((_, i) => (
                            <span
                                key={i}
                                className={`h-2 cursor-pointer rounded-2xl transition-all content-[''] ${activeIndex === i ? "w-8 centexOranceBg" : "w-4  cextexBackgroundColour"}`}
                                onClick={() => setActiveIndex(i)}
                            />
                        ))}
                    </div>
                )}
                prevArrow={({ handlePrev }) => (
                    <IconButton
                        variant="text"
                        color={`orange`}
                        size="lg"
                        onClick={handlePrev}
                        className="!absolute top-2/4 left-4 -translate-y-2/4"
                    >
                        <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke={`${currentTheme === "light" ? "white" : "white"}`}
                            className="h-6 w-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                            />
                        </svg>
                    </IconButton>
                )}
                nextArrow={({ handleNext }) => (
                    <IconButton
                        variant="text"
                        color={`orange`}
                        size="lg"
                        onClick={handleNext}
                        className="!absolute top-2/4 !right-4 -translate-y-2/4"
                    >
                        <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke={`${currentTheme === "light" ? "white" : "white"}`}
                            className="h-6 w-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                            />
                        </svg>
                    </IconButton>
                )}
            >
                {allPromos?.reverse().map((promo, index) => (
                    <section key={index} className='w-full h-screen overflow-hidden relative flex'>
                        <img
                            alt={promo.acf.background_image?.title || 'Promo Image'}
                            src={promo.acf.background_image?.url}
                            loading='lazy'
                            className={`absolute inset-0 w-full h-full object-cover brightness-90
                                `}
                        />
                        <div className={`transition-all mx-auto relative backdrop-blur-sm h-fit flex flex-col self-end p-6 px-16 w-full
                            ${currentTheme === "light" ? "backdrop-brightness-90 border-gray-300 bg-[#ffffff8e]" : "backdrop-brightness-75 border-gray-700 bg-[#0728414f]"}`}>
                            <h1 className={`text-5xl md:text-8xl
                                ${currentTheme === "light" ? "centexTextColour" : "centexOrangeText"}`}>
                                {promo.title.rendered}
                            </h1>
                            <div className='mt-3 ml-1 flex-grow'>
                                <p className='text-2xl md:text-4xl'>{promo.acf.promo_information}</p>
                                <p className='text-lg py-8'>{promo.acf.promo_information_2}</p>
                            </div>
                            <div className='text-2xl pb-6 '>
                                <HeaderLinkButton link={"/cashback/"} title={"Learn More"} />
                            </div>
                        </div>
                    </section>
                ))}
            </Carousel>
        </div>
    )
}

export default HomeCarousel;
