import React, { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { CgMenuRight } from 'react-icons/cg';
import { motion } from 'framer-motion';
import HeaderDropdown from '../ui/HeaderDropdown';
import SocialMediaLinks from '../ui/SocialMediaLinks';
import HeaderLink from '../ui/HeaderLink';
import { useSelector } from 'react-redux';
import HeaderLinkButton from '../ui/HeaderLinkButton';
import SearchWebsite from '../ui/SearchWebsite';
import LanguageSwitchButton from '../ui/LanguageSwitchButton';
import ThemeButton from '../ui/ThemeButton';

const menuVariants = {
    hidden: {
        x: '100%',
    },
    show: {
        x: 0,
        transition: {
            ease: [0.6, 0.01, -0.05, 0.9],
        },
    },
    exit: {
        x: '100%',
        transition: {
            type: 'spring',
        },
    },
};

function MobileHeader() {
    const [openMenu, setOpenMenu] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const [open, setOpen] = React.useState(0);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    const currentTheme = useSelector(state => state.ui.theme);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const threshold = 200;

            if (scrollPosition > threshold) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className='md:hidden z-40'>
            <div className={`text-4xl cursor-pointer transition-all duration-700
                ${currentTheme === "light" ? " centexTextColour" : "text-white"}`}
                onClick={() => setOpenMenu(true)}
            >
                <CgMenuRight />
            </div>
            <motion.div
                variants={menuVariants}
                initial={'hidden'}
                animate={openMenu ? 'show' : 'hidden'}
                exit="exit"
                className={` shadow-2xl w-full absolute top-0 right-0 max-w-xs h-screen
                ${currentTheme === "light" ? "bg-white centexTextColour" : "bg-[#072841] text-white"}`}
            >
                <div
                    className={`${openMenu ? "w-[70vw] -left-[70vw]" : "w-0"} absolute h-screen`}
                    onClick={() => setOpenMenu(false)}
                ></div>
                <div className='text-4xl z-30 cursor-pointer p-5 pt-12' onClick={() => setOpenMenu(false)}>
                    <IoMdClose />
                </div>

                <ul className='h-full flex flex-col items-center gap-y-8 font-extrabold text-xl transition-all'>
                    <li className='text-black flex gap-3 px-5'>
                        <SearchWebsite />
                        <LanguageSwitchButton />
                        <ThemeButton />
                    </li>
                    <li className=''>
                        <HeaderLink title={"Home"} link={"/"} />
                    </li>
                    <li className=''>
                        <HeaderLink title={"Go Market"} link={"/gomarket/"} />
                    </li>
                    <li className=''>
                        <HeaderLink title={"First Nations"} link={"/firstnations/"} />
                    </li>
                    <li>
                        <HeaderDropdown dropdownTitle={"What We Do"} dropdownLink={"/about/"}
                            links={[
                                // { name: 'About Us', link: '/about/' },
                                { name: 'Our History', link: '/about/history' },
                                { name: ' Our Communities', link: '/About/Communities/' },
                            ]}
                        />
                    </li>
                    <li className=''>
                        <HeaderDropdown dropdownTitle={"Our Locations"} dropdownLink={"/locations/"}
                            links={[
                                { name: 'Carwash', link: '/locations/#carwash' },
                                { name: 'First Nations', link: '/locations/#firstNations' },
                                { name: 'Diesel Fule', link: '/locations/#dieselfuel' },
                            ]}
                        />
                    </li>
                    <li>
                        <HeaderDropdown dropdownTitle={"Opportunities"} dropdownLink={"/Opportunities/"}
                            links={[
                                { name: 'Franchise / Dealer', link: '/Opportunities/' },
                                { name: 'Store Operator', link: '/Opportunities/' },
                                { name: 'Career', link: '/Opportunities/' },
                            ]}
                        />
                    </li>
                    <li className=''>
                        <HeaderLinkButton title={"Fuel Program"} link={"/fuelprogram/"} />
                    </li>
                    <li className=''>
                        <HeaderLinkButton title={"Centex Cashback"} link={"/cashback/"} />
                    </li>
                    <li>
                        <SocialMediaLinks />
                    </li>
                </ul>
            </motion.div>
        </nav>
    )
}

export default MobileHeader