import React from 'react'
import { useSelector } from 'react-redux';

function QuickServeRestaurents() {
    const currentTheme = useSelector(state => state.ui.theme);

    return (
        <div className={`overflow-hidden flex flex-col mx-auto text-center rounded-lg p-8
        `}>
            <h3 className={`text-2xl font-semibold pb-3
                ${currentTheme === "light" ? "centexTextColour" : "centexOrangeText"}`}>Quick Service Restaurants</h3>
            <p className="text-lg pb-5">Centex partners with popular quick service restaurant brands to offer convenient food options at select locations.</p>
            {/* <!-- Slider Row --> */}
            <div className="flex flex-wrap gap-5 justify-center">
                <img src={"https://centexbiz.wpcomstaging.com/wp-content/uploads/2024/08/7-11-3954101567-e1724704321376.png"} alt="Seven Eleven" className="h-20" />
                <img src={"https://centexbiz.wpcomstaging.com/wp-content/uploads/2024/08/marble-slab.png"} alt="Marbel Slab Creamery" className="h-20" />
                <img src={"https://centexbiz.wpcomstaging.com/wp-content/uploads/2024/08/subwaySmall.png"} alt="Subway" className="h-20" />
                <img src={"https://centexbiz.wpcomstaging.com/wp-content/uploads/2024/08/Tim-Hortons.png"} alt="Tim Hortons" className="h-20" />
                <img src={"https://centexbiz.wpcomstaging.com/wp-content/uploads/2024/08/Chesters-3798342113-e1724704438696.png"} alt="Chesters" className="h-20" />
            </div>
        </div>
    )
}

export default QuickServeRestaurents