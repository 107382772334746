import React from 'react';
import Logo from '../Logo';
import { FiMapPin, FiPhone } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import SocialMediaLinks from '../ui/SocialMediaLinks';
import { useSelector } from 'react-redux';

function Footer() {
  const currentTheme = useSelector(state => state.ui.theme);

  return (
    <footer className={`text-blue-950 text-center sm:text-left transition-all
    ${currentTheme === "light" ? "bg-white" : "bg-[#072841] text-white"}`}>
      <div className="mx-auto px-6 pt-14 pb-3 ">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-11 px-10">
          <div className="flex flex-col space-y-2 justify-center sm:justify-start">
            <NavLink to="/" className="cursor-pointer">
              <Logo />
            </NavLink>
            <div className="flex justify-center sm:justify-start text-2xl py-2">
              <SocialMediaLinks />
            </div>
            <div className="flex items-center justify-center sm:justify-start">
              <FiPhone className="mr-2" />
              <p>(403) 289-3100</p>
            </div>
            <div className="flex items-center justify-center sm:justify-start">
              <FiPhone className="mr-2" />
              <p>833-2CENTEX</p>
            </div>
            <div className="flex items-center justify-center sm:justify-start">
              <FiMapPin className="mr-2" />
              <p>Calgary Alberta, Canada</p>
            </div>
          </div>
          <div className="space-y-4">
            <h1 className="text-xl font-semibold">Quick Links</h1>
            <ul className="text-gray-500">
              <li><NavLink to="/cashback/" className="footerLink">Cashback Loyalty</NavLink></li>
              <li><NavLink to="/gomarket/" className="footerLink">Go Market</NavLink></li>
              <li><NavLink to="/firstnations/" className="footerLink">First Nations Partnership</NavLink></li>
              <li><NavLink to="/fuelprogram/" className="footerLink">Fule Program</NavLink></li>
            </ul>
          </div>
          <div className="space-y-4">
            <h1 className="text-xl font-semibold">Locations</h1>
            <ul className="text-gray-500">
              <li><NavLink to="/locations/" className="footerLink">All Locations</NavLink></li>
              <li><NavLink to="/locations/#carwash" className="footerLink">Carwash</NavLink></li>
              <li><NavLink to="/locations/#firstnations" className="footerLink">First Nations</NavLink></li>
              <li><NavLink to="/locations/#dieselfuel" className="footerLink">Diesel Fule</NavLink></li>
            </ul>
          </div>
          <div className="space-y-4">
            <h1 className="text-xl font-semibold">About Us</h1>
            <ul className="text-gray-500">
              <li><NavLink to="/about/" className="footerLink">What we do</NavLink></li>
              <li><NavLink to="/about/history" className="footerLink">Our History</NavLink></li>
              <li><NavLink to="/About/Communities/" className="footerLink">Our Communities</NavLink></li>
            </ul>
          </div>
          <div className="space-y-4">
            <h1 className="text-xl font-semibold">Oportunities</h1>
            <ul className="text-gray-500">
              <li><NavLink to="/Opportunities/" className="footerLink">Franchise / Dealer</NavLink></li>
              <li><NavLink to="/Opportunities/" className="footerLink">Store Operator</NavLink></li>
              <li><NavLink to="/Opportunities/" className="footerLink">Career</NavLink></li>
            </ul>
          </div>
          <div className="space-y-4">
            <h1 className="text-xl font-semibold">Information</h1>
            <ul className="text-gray-500">
              <li><NavLink to="/Cashback-terms-of-service" className="footerLink">Cashback Terms of Service</NavLink></li>
              <li><NavLink to="/cashback-faq" className="footerLink">Cashback FAQs</NavLink></li>
            </ul>
          </div>
          
        </div>
        <hr className="my-4 border-t border-gray-600" />
        <div className="text-center text-gray-500 text-sm">
          <p className='p-2'>Website developed and managed by Centex Petroleum.</p>
          <p>&copy; {new Date().getFullYear()} Centex Petroleum. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
