import HomeCarousel from "../Sections/HomeCarousel";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import carwashImg from "../media/Touchless Carwash.jpg"
import BlueSpinner from "../components/spinnders/Spinners";
import HomePagePromoService from "../redux/promos/PromoService";
import HeaderLinkButton from "../components/ui/HeaderLinkButton";
import useScrollToHash from "../utilities/useScrollToHash";
import GoMarketImgOrage from "../media/GoMarket Wordmark.png"
import QuickServeRestaurents from "../Sections/QuickServeRestaurents"
import ImageAndInfobox from "../Sections/ImageAndInfobox";
import CentexCashbackApp from "../Sections/HomePageSections/CentexCashbackApp";
import GoMarketQuickServe from "../Sections/HomePageSections/GoMarketQuickServe";

function Home() {
    useScrollToHash();
    const [promos, setPromos] = useState([]);
    const isLoading = useSelector(state => state.promos.isLoading)
    const currentTheme = useSelector(state => state.ui.theme);

    useEffect(() => {
        const fetchPromos = async () => {
            try {
                if (promos?.length === 0) {
                    setPromos(await HomePagePromoService.getAllPromos());
                }
            } catch (e) {
                console.error('Error Fetching Promos')
            }
        }
        fetchPromos();
    }, [])

    return (
        <section id="home" className={`mx-auto min-h-screen
        ${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>
            {promos.length === 0 ? (
                <div className="w-full h-screen flex items-center justify-center">
                    <BlueSpinner />
                </div>
            ) : (
                <HomeCarousel
                    allPromos={promos}
                />
            )}

            {/* Sections after the home page slider */}
            <div className="flex flex-col">
                {/* Centex Cashback and Fuel Program Details */}
                <section id="loyaltyProgramsSection" className={`flex flex-col justify-center items-center  bg-opacity-50 section py-24
                     ${currentTheme === "light"
                        ? "bg-gradient-to-tr from-[#ffffff00] to-[#B9D6E9]"
                        : "bg-gradient-to-tr from-[#07284100] to-[#072841]"
                    }`} >
                    <h2 className={`text-4xl font-extrabold mb-6 text-center
                        ${currentTheme === "light" ? "centexTextColour" : "centexOrangeText"}`}>Unlock Exclusive Benefits with Centex Loyalty Programs!</h2>
                    <p className="text-lg text-center mb-8 max-w-3xl">Maximize your savings with the Centex Fuel Pass and Cashback Card! Simplify fuel expense management for your fleet and earn instant cashback on fuel and in-store purchases. Join Centex today to enjoy hassle-free fueling and rewarding shopping experiences.</p>
                    <div className="flex flex-col md:flex-row justify-around w-full text-center">
                        {/* Cashback */}
                        <div className={`bg-opacity-90 rounded-lg shadow-md m-4 w-fit md:w-[45%]
                            ${currentTheme === "light" ? "backdrop-brightness-90 border-gray-300 bg-[#ffffff8e]" : "backdrop-brightness-75 border-gray-700 bg-[#093353]"}`}>
                            <img src={"https://centexbiz.wpcomstaging.com/wp-content/uploads/2024/08/Cashback-2274296062-e1723066972355.png"}
                                className={`object-cover w-full h-64 mx-auto rounded-t-lg brightness-90`} />
                            <div className="p-6">
                                <h3 className={`text-2xl font-semibold mb-4
                                ${currentTheme === "light" ? "centexTextColour" : "centexOrangeText"}`}>
                                    Centex Cashback Card
                                </h3>
                                <p className="mb-6">Earn cashback on every fuel and in-store purchase with the Centex Cashback Card. Enjoy instant savings and rewards!</p>

                                <div className='text-xl h-fit mt-6'>
                                    <HeaderLinkButton link={"/cashback/"} title={"Learn More"} />
                                </div>
                            </div>
                        </div>
                        {/* Fule program */}
                        <div className={`bg-opacity-90 rounded-lg shadow-md m-4 w-fit md:w-[45%]
                            ${currentTheme === "light" ? "backdrop-brightness-90 border-gray-300 bg-[#ffffff8e]" : "backdrop-brightness-75 border-gray-700 bg-[#093353]"}`}>
                            <img src={"https://centexbiz.wpcomstaging.com/wp-content/uploads/2024/08/AdobeStock_513736510.png"}
                                className={`object-cover w-full h-64 mx-auto rounded-t-lg brightness-90`} />
                            <div className="p-6">
                                <h3 className={`text-2xl font-semibold mb-4
                                ${currentTheme === "light" ? "centexTextColour" : "centexOrangeText"}`}>
                                    Fuel Pass Program
                                </h3>
                                <p className=" mb-6">Keep your fleet moving with our tailored Fuel Pass program. Simplify fuel expenses and enjoy hassle-free management for your business.</p>
                                <div className='text-xl h-fit'>
                                    <HeaderLinkButton link={"/fuelprogram/"} title={"Learn More"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <CentexCashbackApp />

                {/* Locations */}
                <section id="locationsSection" className="text-left section py-16 bg-opacity-50 ">
                    {/* All Locations Section */}
                    <div className="flex flex-col items-center text-center pb-10 px-4 md:px-10">
                        <h2 className={`text-4xl font-extrabold mb-6
                            ${currentTheme === "light" ? "centexTextColour" : "centexOrangeText"}`}
                        >
                            Explore Over 150 Centex Locations Nationwide!
                        </h2>
                        <p className="text-lg mb-6 max-w-3xl">
                            Experience convenience like never before with Centex. Our extensive network of over 150 locations across Canada ensures that you’re always close to reliable fueling and exceptional services. Whether you're on the road or close to home, Centex is here to meet all your fueling needs.
                        </p>
                        <div className="mb-6 text-xl">
                            <HeaderLinkButton link={"/locations/"} title={"Explore our locations"} />
                        </div>
                    </div>

                    {/* Carwash Locations Section */}
                    <ImageAndInfobox
                        img={carwashImg}
                        title={"Discover Our Premium Carwash Services!"}
                        text={"Looking for a sparkling clean vehicle? Our dedicated carwash locations provide top-notch services to keep your vehicle looking its best. With Centex, you get more than just fueling—you get a full-service experience."}
                        linkName={"Find a Carwash"}
                        link={"/locations/#carwash"}
                        id={"carwashSection"}
                    />
                </section>

                {/* First Nations */}
                <section id="firstNationsSection" className="section relative py-20 bg-[#063b12] bg-opacity-50 md:flex md:items-start md:space-x-10">
                    <div className="w-full md:w-1/2 text-center md:text-left mb-10 md:mb-0">
                        <h2 className={`text-5xl font-extrabold leading-tight ${currentTheme === "light" ? "centexTextColour" : "centexOrangeText"}`}>
                            Our Partnership with First Nations
                        </h2>
                        <p className="text-lg my-8">
                            Centex is dedicated to empowering First Nations through supportive partnerships. We focus on community ownership, providing reliable fuel supply, project management, and ongoing support to foster growth and prosperity. Our goal is to enhance economic opportunities and ensure mutual success.
                        </p>
                        <div className="text-lg">
                            <HeaderLinkButton link={"/firstnations/"} title={"Learn More"} />
                        </div>
                    </div>
                    <div className={`bg-opacity-80 rounded-lg shadow-lg p-10 w-full md:w-1/2 text-center ${currentTheme === "light" ? "backdrop-brightness-90 border-gray-300 bg-[#ffffff8e]" : "backdrop-brightness-75 border-gray-700 bg-[#093353]"}`
                    }>
                        <blockquote className="text-base italic mb-6">
                            "Centex's respect and honesty are key."
                            <span className="block mt-2 font-semibold">— Chief Greg Desjarlais, Frog Lake Cree Nation</span>
                        </blockquote>
                        <blockquote className="text-base italic mb-6">
                            "Centex's integrity is commendable."
                            <span className="block mt-2 font-semibold">— Chief Stanley Grier, Piikani First Nation</span>
                        </blockquote>
                    </div>
                </section>

                {/* Go Market */}
                {/* <section id="goMarketSection" className=" section py-10">
                    <img
                        src={GoMarketImgOrage}
                        className="h-10 md:h-14 mx-auto md:mx-0"
                    />
                    <ImageAndInfobox
                        img={"https://centexbiz.wpcomstaging.com/wp-content/uploads/2024/08/LM_05247-2176348483-e1724701666841.jpg"}
                        title={"All the Conveniences You're Looking For"}
                        text={"Grab what you need and go with Centex's Go Market. Our convenience stores are stocked full of everyday necessities, including:"}
                        text2={"Snack Foods, Soft Drinks, Hot and Fresh Foods, Coffee, Tea, and Specialty Beverages, Toiletries Magazines, Lottery and Scratch Tickets, Bagged Ice, In-Store ATMs, and More"}
                        linkName={"Learn More"}
                        link={"/gomarket/"}
                    />
                    <QuickServeRestaurents />
                </section> */}

                <GoMarketQuickServe />
            </div>

        </section >
    );
}

export default Home;