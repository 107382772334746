import React from 'react'
import { useSelector } from 'react-redux';
import useScrollToHash from '../utilities/useScrollToHash';

function About() {
    const currentTheme = useSelector(state => state.ui.theme);
    useScrollToHash();
    return (
        <section className={`section min-h-screen pt-32
        ${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>
            <div className='py-5'>
                <h2 className='text-2xl font-bold mb-4'>What is Centex?</h2>
                <p className='text-lg'>Centex is 100% Western Canadian owned and operated, standing proudly as an independent entity in the fuel industry. By offering consistently competitive fuel prices, convenient locations, and a wide range of services for people on the go, we position ourselves as a fresh alternative to the big corporations.</p>
            </div>
            <div className='text-left py-5'>
                <h3 className='text-xl font-semibold mb-3'>Competitive Pricing</h3>
                <ul className='list-disc pl-5'>
                    <li className='mb-2'>We shop around daily, purchasing fuel based on the best wholesale prices and availability, ensuring our customers get the most competitive prices.</li>
                    <li className='mb-2'>Independence: As a chain with no affiliation or obligation to any one supplier, we maintain our independence and flexibility, allowing us to offer the best value to our customers.</li>
                    <li className='mb-2'>Convenience: With strategically located sites and a variety of services, we cater to the needs of busy people, providing a seamless and efficient experience.</li>
                </ul>
            </div>
            <div className='text-left py-5'>
                <h3 className='text-xl font-semibold mb-3'>Why Choose Centex?</h3>
                <p className='text-lg'>Our independence and innovative approach to fuel purchasing set us apart. We're not just about great prices and convenience—we're also committed to creating a fun and friendly atmosphere for our customers.</p>
            </div>

            <div className='text-left py-5'>
                <h2 className='text-2xl font-bold mb-4'>Why Customers Love Centex Stations</h2>
                <p className='text-lg'>At Centex, we're dedicated to providing an exceptional experience for our customers by keeping things simple and straightforward. Here's why our customers keep coming back and loving our stations:</p>

                <div className='py-5'>
                    <h3 className='text-xl font-semibold mb-3'>Simplicity and Convenience</h3>
                    <p className='text-lg'>Easy to Use: Whether it's fueling up or shopping in-store, we make every interaction simple and hassle-free. Our Centex Cashback Card and app streamline the process of earning and redeeming rewards.</p>
                </div>

                <div className='py-5'>
                    <h3 className='text-xl font-semibold mb-3'>Competitive Prices</h3>
                    <p className='text-lg'>Better Value: We offer the look and feel of major gasoline retailers but with more competitive prices. Our goal is to give you more for your money without compromising on quality or service.</p>
                </div>

                <div className='py-5'>
                    <h3 className='text-xl font-semibold mb-3'>Superior Customer Service</h3>
                    <p className='text-lg'>Friendly and Helpful Staff: Our team is committed to providing the best service possible. From greeting you with a smile to assisting with any questions, we're here to make your experience enjoyable.</p>
                    <p className='text-lg'>Customer-Centric Approach: We listen to our customers and continuously strive to meet their needs and preferences, ensuring a pleasant experience every time you visit.</p>
                </div>

                <div className='py-5'>
                    <h3 className='text-xl font-semibold mb-3'>Variety of Products and Services</h3>
                    <p className='text-lg'>Wide Selection: Our stations offer a broad range of products and services, comparable to major retailers, ensuring you can find what you need all in one place.</p>
                    <p className='text-lg'>Quality Assurance: We maintain high standards for all our products, ensuring you get the best quality every time.</p>
                </div>

                <div className='py-5'>
                    <h2 className='text-2xl font-bold mb-4'>The Centex Cashback Program</h2>
                    <p className='text-lg'>Instant Rewards: Earn cashback instantly on fuel and in-store purchases with the Centex Cashback Card.</p>
                    <p className='text-lg'>Flexible Redemption: Redeem your cashback easily once your card is registered, with options to use your rewards in increments that suit you.</p>
                </div>

                <div className='py-5'>
                    <h2 className='text-2xl font-bold mb-4'>Fuel Quality and Standards</h2>
                    <h3 className='text-xl font-semibold mb-3'>What are you putting in YOUR fuel tank?</h3>
                    <p className='text-lg'>At Centex, all of our fuels are refined and processed by major Western Canadian refiners. The fuel is produced for Canadian driving conditions—especially winter—and meets or exceeds all federal and provincial standards. This has resulted in our customers' confidence in the quality of our fuels.</p>

                    <h3 className='text-xl font-semibold mb-3'>Premium</h3>
                    <p className='text-lg'>We sell the same higher octane "Premium" or "Super" fuel as the major retailers, with an octane rating of 91 octane or higher. But for LESS! Check and compare our premium prices with our competitors. You'll be surprised.</p>

                    <h3 className='text-xl font-semibold mb-3'>Diesel</h3>
                    <p className='text-lg'>We sell ultra low sulphur diesel fuel for on-road use for passenger cars, pick-ups, and commercial trucks that have diesel engines. We refrain from adding additional additives that may gum up or block fuel injectors or possibly damage essential diesel engine parts.</p>
                </div>

                <div className='py-5'>
                    <h2 className='text-2xl font-bold mb-4'>Due Diligence</h2>

                    <h3 className='text-xl font-semibold mb-3'>Calibration</h3>
                    <p className='text-lg'>Are you getting what you paid for? At Centex, we pride ourselves on having a measurement and accuracy program that exceeds government guidelines and recommendations. We ensure the accuracy of our pumps by having an independently monitored and implemented pump calibration program that regularly verifies and calibrates our pumps at all Centex locations.</p>

                    <h3 className='text-xl font-semibold mb-3'>Tank Monitoring</h3>
                    <p className='text-lg'>Centex locations are equipped with state-of-the-art Incon tank monitoring systems. This system electronically monitors all the fuel tanks on a site against any moisture or water accumulation in the tanks. It also looks for any loss of fuel due to a breach or a leak.</p>

                    <h3 className='text-xl font-semibold mb-3'>Environment</h3>
                    <p className='text-lg'>Our sites have double-walled fiberglass tanks and piping and environmental sumps under the pumps to ensure against any environmental contamination from any loss of fuel.</p>
                </div>

                <div className='py-5'>
                    <h2 className='text-2xl font-bold mb-4'>Fuel Up On Savings</h2>
                    <p className='text-lg'>Take advantage of our special weekly offer: Every Wednesday, Saturday, and Sunday, instantly save up to 4¢ per litre on fuel at participating Centex locations.</p>
                    <p className='text-lg'>Save more and get more by simply choosing Centex for all your refueling needs.</p>
                </div>

                <div className='py-5'>
                    <h2 className='text-2xl font-bold mb-4'>Customer Testimonials</h2>
                    <p className='text-lg'>Our customers often share their positive experiences with us, and here's what some of them have to say:</p>
                    <blockquote className='border-l-4 border-blue-500 pl-4 text-lg mb-4'>"I love how simple and straightforward everything is at Centex. The cashback rewards are a fantastic bonus!" - Sarah J.</blockquote>
                    <blockquote className='border-l-4 border-blue-500 pl-4 text-lg mb-4'>"The staff at Centex stations are always friendly and helpful. Plus, the prices are unbeatable!" - Mark T.</blockquote>
                    <blockquote className='border-l-4 border-blue-500 pl-4 text-lg mb-4'>"Great variety of products and top-notch service every time. Centex really understands what customers need." - Emily R.</blockquote>
                </div>
            </div>
        </section>
    )
}

export default About
