import React from 'react'
import { useSelector } from 'react-redux';
import GoMarketStoreImg from '../../media/GoMarketQuickServe/GoMarketStoreImg.svg'
import HeaderLinkButton from '../../components/ui/HeaderLinkButton';
import QuickServeRestaurentsImg from '../../media/GoMarketQuickServe/QuickServeRestaurents.svg'


function GoMarketQuickServe() {
    const currentTheme = useSelector(state => state.ui.theme);

    return (
        <section id="goMarketSection" className="flex flex-col justify-center items-center bg-opacity-50 pt-12 lg:pt-24">
            <h2
                className={`text-4xl lg:text-6xl font-extrabold mb-4 text-center 
            ${currentTheme === "light" ? "centexTextColour" : "centexOrangeText"}
        `}
            >
                GoMarket & Quick Service Restaurants
            </h2>

            <div className="flex flex-col lg:flex-row gap-5 py-10 lg:py-20 items-center lg:items-start px-4 lg:px-0 section">
                {/* GoMarket Store Image */}
                <img src={GoMarketStoreImg} className="w-full lg:w-auto max-w-md lg:max-w-none" alt="GoMarket Store" />

                {/* Text Content */}
                <div className="lg:ml-10 text-center lg:text-left mt-8 lg:mt-0">
                    <h3 className="text-3xl lg:text-4xl centexOrangeText font-semibold">
                        All the conveniences you're looking for
                    </h3>
                    <p className="font-thin text-xl lg:text-3xl pb-6 pt-6 lg:pt-10 lg:pr-28">
                        Grab what you need and go with Centex's Go Market. Our GoMarket convenience stores are stocked full
                        of everyday necessities including snack foods, soft drinks, hot and fresh foods, coffee, tea,
                        specialty beverages, toiletries, magazines, and more.
                    </p>
                    <HeaderLinkButton link={"/goMarket/"} title={"Learn More"} />
                </div>
            </div>

            {/* Quick Service Restaurants Image */}
            <div className="bg-[#4B595B] w-full py-8 lg:py-12">
                <img src={QuickServeRestaurentsImg} className="mx-auto max-w-full lg:max-w-3xl" alt="Quick Service Restaurants" />
            </div>
        </section>

    )
}

export default GoMarketQuickServe