import React from 'react';
import { useSelector } from 'react-redux';
import useScrollToHash from '../utilities/useScrollToHash';

function FuelProgram() {
  const currentTheme = useSelector(state => state.ui.theme);
  useScrollToHash();
  return (
    <section className={`section min-h-screen pt-32
    ${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>
      <div className=' mb-8 '>
        <h2 className='text-xl font-semibold mb-4'>
          Fuel Program: Convenient Solutions for Your Fuel Requirements
        </h2>
        <p className=''>
          At Centex, we offer tailored solutions to meet the fueling needs of businesses with vehicle fleets. Our Fuel Pass program provides an easy and efficient way to manage your fuel expenses, ensuring your fleet stays on the move without hassle.
        </p>
      </div>
      <div className=' mb-8 '>
        <h3 className='text-lg font-semibold mb-4'>Fuel Your Fleet with Our Fuel Pass</h3>
        <p className=''>
          If you have a fleet of vehicles and are looking for a convenient solution to your fuel requirements, consider our Fuel Pass program.
        </p>
        <div className=' mb-8 '>
          <h4 className='text-base font-semibold mb-2'>How to Get Started:</h4>
          <ul className='list-disc ml-6'>
            <li>Fill Out the Application: Complete the Fuel Pass application form.</li>
            <li>Send It In: Submit the filled-out PDF to us.</li>
          </ul>
        </div>
        <div className=' mb-8 '>
          <h4 className='text-base font-semibold mb-2'>Accepted Third-Party Corporate Fleet Accounts</h4>
          <p className=''>
            For customers with existing third-party corporate fleet accounts, most of our sites accept the following providers:
          </p>
          <ul className='list-disc ml-6 '>
            <li>ARI & Federal Government</li>
            <li>Corp-Rate</li>
            <li>Foss National Leasing</li>
            <li>GE Capital (Element Fleet)</li>
            <li>Jim Pattison Lease</li>
            <li>PH&H (Element Fleet)</li>
            <li>Element Fleet Services</li>
            <li>WEX</li>
          </ul>
        </div>
      </div>
      <h3 className='text-lg font-semibold'>
        Explore the benefits of the Centex Fuel Pass and streamline your fleet's fueling process. Contact us today to learn more and get started!
      </h3>
    </section>
  );
}

export default FuelProgram;
